import type React from "react";

import { AuthStorage } from "../local-storage";
import Config from "../config";

export const onClickAccessible = (callback: () => void) => {
  return {
    onMouseDown: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      callback();
      e.preventDefault();
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        callback();
      }
    },
  };
};

export const logout = () => {
  if (Config.isDev) {
    const authStorage = new AuthStorage();
    authStorage.clear();
  }
  window.location.href = "/logout";
};

export function assertIsError(error: unknown): asserts error is Error {
  if (!(error instanceof Error)) {
    throw error;
  }
}
